body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* GLOBAL */

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  /* font-size: 1.1vw; */
}

@media (min-width: 901px) {
  
  .First, .Second, .Third {
    height: 100vh;
    min-height: 546px;
  }
  
  .First, .Third {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
  }

  .LogoContainer { 
    /* fixed position a zero-height full width container */
    position: fixed;
    top: 0; /* or whatever position is desired */
    left: 0;
    right: 0;
    height: 0;
    /* center all inline content */
    text-align: center;
    top: 33%;
}

.orange {
  color: #ff4d00;
}

  /* FIRST */
  
  .First {
    background-image: url(/static/media/doctors.93380efd.jpg);
  }
  
  /* SECOND */
  
  .Second {
    display: -webkit-flex;
    display: flex;
  }
  
  .Left, .Right {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .LeftInner, .RightInner {
    width: 65%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: 15vh 0;
    font-size: 1em;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .Portrait {
    object-fit: contain;
    width: 100%;
    height: 34vw;
  }
  
  .Right {
    background-color: #031f3e;
    color: #ffffff;
    font-size: 1.6vw;
    overflow: hidden;
  }
  
  .RightInner > p {
    margin: 0;
    max-width: 30vw;
    overflow: scroll;
    font-size: 0.9em;
  }
  
  /* THIRD */
  
  .Third {
    background-image: url(/static/media/leaf.cbc5bfcf.jpg);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .KontaktneInformacije-first {
    margin-bottom: 0 0 200px 0;
  }
  
  .KontaktneInformacije-second {
    margin: 200px 0 0 0;
  }

  .KontaktneInformacije-first, .KontaktneInformacije-second {
    width: 50%;
    font-size: 1.6em;
    text-align: center;
  }
  
  /* FOOTER */
  
  .Footer {
    height: 8vh;
    display: -webkit-flex;
    display: flex;
    color: #031f3e;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .MatjazVrtovec {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    margin-left: 6%;
  }
  
  .FooterElement {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
}



@media (min-width: 501px) and (max-width: 1275px) {

  .logo {
    max-width: 150px;
  }

}

@media (max-width: 900px) {

  .First, .Third {
    height: 100vh;
  }

  /* FIRST  mobile*/

  .First {
    background-image: url(/static/media/doctors-mobile.8654e407.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  /* SECOND mobile*/

  .Second {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  
  .Right {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .Left {
    height: 60vh;
  }

  .LeftInner {
    position: absolute;
    font-size: 0.8em;
  }

  .LeftInner > p {
    position: relative;
    padding: 0 17.5vw;
    color: #031f3e;
  }

  .Portrait {
    max-width: 65vw;
    overflow: visible;
    margin-top: -14vh;

    position: relative;
    padding: 0 17.5vw;
  }

  .Right {
    height: 100vh;
  
    background-color: #031f3e;
    color: #ffffff;
    font-size: 1em;
    overflow: hidden;
    padding: 17vw;
  }
  
  .RightInner > p {
    margin: 7vh 0;
    font-size: 0.9em;
  }

  .SmallLogo {
    height: 13vw;
    width: 13vw;

    background-image: url(/static/media/matjaz-vrtovec-white-logo.ef305fbd.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: block;
    margin: 0 auto;
  }

  /* THIRD mobile*/ 

  .Third {
    background-image: url(/static/media/leaf.cbc5bfcf.jpg);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    -webkit-flex-direction: column;

            flex-direction: column;
    font-size: 1.2em;
  }
    
  .KontaktneInformacije-first,   .KontaktneInformacije-second {
    width: 50%;
    text-align: center;
    color: white;
  }

  /* FOOTER mobile*/
  
  .Footer {
    height: 6vh;
    display: -webkit-flex;
    display: flex;
    color: #031f3e;
    -webkit-align-items: center;
            align-items: center;
    font-size: 0.7em;
  }
  
  .MatjazVrtovec {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    margin-left: 6%;
  }
  
  .FooterElement {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

}
